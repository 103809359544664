// // public/service-worker.js
// self.addEventListener('install', (event) => {
//     console.info("check 0..................install addEventListener.....");
//     event.waitUntil(
//         caches.open('static-v1').then((cache) => {
//             return cache.addAll([
//                 '/',
//                 // '/index.html',
//                 // '/styles.css',
//                 // '/script.js',
//                 // // Add other assets to cache
//             ]);
//         })
//     );
// });

// const CACHING_DURATION = 24 * 3600;
// const DEBUG = true;

// self.addEventListener('fetch', (event) => {
//   const { request } = event;
//   console.log("check 1............fetch addEventListener.....", request.url);
//   event.respondWith(caches.open("static-v1").then(
//     cache => cache.match(request).then(
//       (response) => {
//         // If there is a match from the cache
//         if (response) {
//           console.log(`SW: serving ${request.url} from cache after checking cache expiration.`);
//           const expirationDate = Date.parse(response.headers.get('sw-cache-expires'));
//           const now = new Date();
//           // Check it is not already expired and return from the
//           // cache
//           console.log({ CHECK_if_expired: expirationDate > now, expirationDate, now });
//           if (expirationDate > now) {
//             console.log(`SW: serving ${request.url} from cache - validated...`);
//             return response;
//           }
//         }

//         // Otherwise, let's fetch it from the network
//         console.log(`SW: no match in cache for ${request.url}, using network.`);
//         return fetch(request.url).then((liveResponse) => {
//           if (request.url.endsWith(".js") && !request.url.includes("service-worker.js")) {
//             // Compute expires date from caching duration
//             const expires = new Date();
//             expires.setSeconds(
//               expires.getSeconds() + CACHING_DURATION,
//             );
//             // Recreate a Response object from scratch to put
//             // it in the cache, with the extra header for
//             // managing cache expiration.
//             const cachedResponseFields = {
//               status: liveResponse.status,
//               statusText: liveResponse.statusText,
//               headers: { 'SW-Cache-Expires': expires.toUTCString() },
//             };
//             liveResponse.headers.forEach((v, k) => {
//               cachedResponseFields.headers[k] = v;
//             });
//             // We will consume body of the live response, so
//             // clone it before to be able to return it
//             // afterwards.
//             const returnedResponse = liveResponse.clone();
//             return liveResponse.blob().then((body) => {
//               console.log(
//                 `SW: caching tiles ${request.url} until ${expires.toUTCString()}.`,
//               );
//               // Put the duplicated Response in the cache
//               cache.put(request, new Response(body, cachedResponseFields));
//               // Return the live response from the network
//               return returnedResponse;
//             });
//           } else {
//             console.log("not caching other files....return fetch response", request.url);
//             return liveResponse;
//           }
//         });
//       })
//   )
//   );
// });

// self.addEventListener('message', (event) => {
//   console.log(`SW: received message `, event);
//   const eventData = JSON.parse(event.data);
//   console.log({ eventData });
//   // Clean tiles cache when we receive the message asking to do so
//   if (eventData.action === 'PURGE_EXPIRED_CACHE') {
//     DEBUG && console.log('SW: purging expired cache.');
//     global.caches.open(`static-v1`).then(
//       cache => cache.keys().then(
//         keys => keys.forEach(
//           // Loop over all requests stored in the cache and get the
//           // matching cached response.
//           key => cache.match(key).then((cachedResponse) => {
//             // Check expiration and eventually delete the cached
//             // item
//             const expirationDate = Date.parse(cachedResponse.headers.get('sw-cache-expires'));
//             const now = new Date();
//             console.log({ expirationDate, now, IS_EXPIRED: expirationDate < now });
//             if (expirationDate < now) {
//               DEBUG && console.log(`SW: purging (expired) tile ${key.url} from cache.`);
//               cache.delete(key);
//             }
//           }),
//         ),
//       ),
//     );
//   }
// });

var isValid = function(response) {
  if (!response) return false;
  var fetched = response.headers.get("sw-fetched-on");
  if (
    fetched &&
    parseFloat(fetched) + 1000 * 60 * 60 * 2 > new Date().getTime()
  )
    return true;
  return false;
};

let cacheStoreName = "static-v1";
if (process.env.AWS_JOB_ID) {
  console.log(
    "using AWS_JOB_ID for cache store name....",
    process.env.AWS_JOB_ID
  );
  cacheStoreName = `tc-cache-${process.env.AWS_JOB_ID}`;
}

// try {
//   if (typeof window !== 'undefined' && window.localStorage) {
//     if (window.localStorage.getItem("metaJsonBuildId")) {
//       let metaJsonBuildId = window.localStorage.getItem("metaJsonBuildId");
//       console.log("using metaJsonBuildId for cache store name....", metaJsonBuildId);
//       if (metaJsonBuildId && metaJsonBuildId.length) {
//         cacheStoreName = `tc-cache-${metaJsonBuildId}`;
//       }
//     }
//   }
// } catch (error) {
//   console.error('Failed to read from localStorage:', error);
// }
// try {
//   let metaJsonBuildId = self.localStorage.getItem("metaJsonBuildId");
//   console.log("using metaJsonBuildId (2) for cache store name....", metaJsonBuildId);
//   if (metaJsonBuildId && metaJsonBuildId.length) {
//     cacheStoreName = `tc-cache-${metaJsonBuildId}`;
//   }
// } catch (e) {
//   console.error('SW Error:', e);
// }
// console.log("cacheStoreName....", cacheStoreName);

// self.onmessage = function (event) {
//   console.log("SW Received Message: ", event.data);
// }

self.addEventListener("fetch", event => {
  console.info(
    "check 1............fetch addEventListener.....",
    event.request.url
  );
  if (
    (event.request.url.endsWith(".js") ||
      event.request.url.endsWith(".css") ||
      ((event.request.url.startsWith("/") ||
        event.request.url.startsWith(process.env.BASE_URL)) &&
        !event.request.url.includes("/attachments/") &&
        (event.request.url.endsWith(".png") ||
          event.request.url.endsWith(".jpg") ||
          event.request.url.endsWith(".jpeg") ||
          event.request.url.endsWith(".gif") ||
          event.request.url.endsWith(".bmp") ||
          event.request.url.endsWith(".svg")))) &&
    !event.request.url.includes("service-worker.js")
  ) {
    event.respondWith(
      caches.match(event.request).then(function(response) {
        console.info("response from cache....", response);
        // If there's a cached API and it's still valid, use it
        if (isValid(response)) {
          console.info("return valid cached response....");
          return response;
        }

        // Otherwise, make a fresh API call
        return fetch(event.request)
          .then(function(response) {
            // Cache for offline access
            var copy = response.clone();
            event.waitUntil(
              caches.open(cacheStoreName).then(function(cache) {
                var headers = new Headers(copy.headers);
                headers.append("sw-fetched-on", new Date().getTime());
                return copy.blob().then(function(body) {
                  return cache.put(
                    event.request,
                    new Response(body, {
                      status: copy.status,
                      statusText: copy.statusText,
                      headers: headers
                    })
                  );
                });
              })
            );
            // Return the requested file
            return response;
          })
          .catch(function(e) {
            console.info(
              "error in fetching (service worker - cached section)...",
              e,
              event.request.url
            );
            // fallback for case when the request is not found in cache and not able to fetch from network (either updated or not found)
            // Post a message to the client to show a modal
            if (
              !event.request.url.includes("service-worker.js") &&
              event.request.url.endsWith(".js") &&
              event.request.url.startsWith(process.env.BASE_URL)
            ) {
              console.log("fallback refresh modal for js file....");
              self.clients.matchAll().then(clients => {
                clients.forEach(client => {
                  client.postMessage({
                    type: "SHOW_REFRESH_MODAL"
                  });
                });
              });
            }
          });
      })
    );
  } else {
    // return fetched response
    if (
      !event.request.url.includes("service-worker.js") &&
      (event.request.url.startsWith("/") ||
        event.request.url.startsWith(process.env.BASE_URL))
    ) {
      console.info("fetching from service worker....", event.request.url);
      event.respondWith(
        fetch(event.request)
          .then(function(response) {
            return response;
          })
          .catch(function(e) {
            console.info(
              "error in fetching (service worker)....",
              e,
              event.request.url
            );
          })
      );
    } else {
      console.info("handle other fetch requests", event.request.url);
    }
  }
});

// self.addEventListener("fetch", event => {
//   console.info(
//     "check 1............fetch addEventListener.....",
//     event.request.url
//   );
//   event.respondWith(
//     caches.open("static-v1").then(cache => {
//       cache.match(event.request).then(function (cachedResponse) {
//         console.info("response from cache....", cachedResponse);
//         if (cachedResponse) {
//           console.info("returning cached response...");
//           return cachedResponse;
//         } else {
//           return fetch(event.request).then(function (newResponse) {
//             if (
//               newResponse &&
//               event.request.url.endsWith(".js") &&
//               !event.request.url.includes("service-worker.js")
//             ) {
//               console.info("adding to cache....", event.request.url);

//               // return caches.open("static-v1").then(function (cache) {
//               //   // let newResponse = new Response(res.body, {
//               //   //   status: res.status,
//               //   //   statusText: res.statusText,
//               //   //   headers: { ...res.headers, "x-sw-cache": new Date().getTime() }
//               //   // });
//               //   // // newResponse.headers.set("x-sw-cache", new Date().getTime());
//               //   // cache.put(event.request.url, newResponse);
//               //   cache.put(event.request.url, newResponse.clone());
//               //   return newResponse;
//               // });
//             } else {
//               return newResponse;
//             }
//           });
//         }
//       })
//     })
//   );

//   // working code to cache js files

//   // event.respondWith(
//   //   caches.match(event.request).then(function(response) {
//   //     console.info("response from cache....", response);
//   //     if (response) {
//   //       console.info("returning cached response...");
//   //       return response;
//   //     } else {
//   //       return fetch(event.request).then(function(res) {
//   //         if (
//   //           res &&
//   //           res.status &&
//   //           event.request.url.endsWith(".js") &&
//   //           !event.request.url.includes("service-worker.js")
//   //         ) {
//   //           console.info("adding to cache....", event.request.url);
//   //           return caches.open("static-v1").then(function(cache) {
//   //             // let newResponse = new Response(res.body, {
//   //             //   status: res.status,
//   //             //   statusText: res.statusText,
//   //             //   headers: { ...res.headers, "x-sw-cache": new Date().getTime() }
//   //             // });
//   //             // // newResponse.headers.set("x-sw-cache", new Date().getTime());
//   //             // cache.put(event.request.url, newResponse);
//   //             cache.put(event.request.url, res.clone());
//   //             return res;
//   //           });
//   //         } else {
//   //           return res;
//   //         }
//   //       });
//   //     }
//   //   })
//   // );

//   // ------

//   // event.respondWith(
//   //   caches.match(event.request).then(response => {
//   //     console.info("response from cache....", response);
//   //     return response || fetch(event.request).then(response => {
//   //       event.waitUntil(caches.open("static-v1").then(cache => {
//   //         // if (event.request.url.endsWith(".js") && response.status == 200) {  //  || event.request.url.endsWith(".css")
//   //         //   console.info("adding to cache....", event.request.url, new Date().getTime());
//   //         //   let responseCopy = new Response(response.body, {
//   //         //     status: response.status,
//   //         //     statusText: response.statusText,
//   //         //     headers: { ...response.headers, 'x-sw-cache': new Date().getTime() },
//   //         //   });
//   //         //   cache.put(event.request, responseCopy);
//   //         // }
//   //         if (event.request.url.endsWith(".js") && response.status == 200) {
//   //           console.info("adding to cache....", event.request.url);
//   //           cache.put(event.request, response);
//   //         }
//   //         return response;
//   //       }));
//   //     });
//   //   })
//   // );

//   // event.respondWith(
//   //   // match the request with the cache if found then return else fetch the request and add it to the cache and return
//   //   caches.match(event.request).then(response => {
//   //     console.info("response from cache....", response);
//   //     if (response && response.headers && response.headers.get('x-sw-cache')) {
//   //       console.info("response from cache with timestamp....", response.headers.get('x-sw-cache'));
//   //       const cacheTimestamp = response.headers.get('x-sw-cache');
//   //       const currentTimestamp = new Date().getTime();
//   //       const diff = currentTimestamp - cacheTimestamp;
//   //       console.info("diff between current and cache timestamp....", diff);
//   //       // if the difference is greater than 1 day then fetch the request and update the cache
//   //       if (diff > 120000) {  // 86400000 ms = 1 day
//   //         console.info("cache expired for this request....", event.request.url);
//   //         response = null;
//   //       }
//   //     }
//   //     return response || fetch(event.request).then(response => {
//   //       event.waitUntil(caches.open("static-v1").then(cache => {
//   //         if (event.request.url.endsWith(".js") && response.status == 200) {  //  || event.request.url.endsWith(".css")
//   //           console.info("adding to cache....", event.request.url, new Date().getTime());
//   //           // also add a timestamp with the response to check if the response is coming from cache or not
//   //           // var copy = response.clone();
//   //           // var headers = new Headers(copy.headers);
//   //           // headers.append('x-sw-cache', new Date().getTime());
//   //           // copy.blob().then(function (body) {
//   //           //   cache.put(event.request, new Response(body, {
//   //           //     status: copy.status,
//   //           //     statusText: copy.statusText,
//   //           //     headers: headers
//   //           //   }));
//   //           // });

//   //           let responseCopy = new Response(response.body, {
//   //             status: response.status,
//   //             statusText: response.statusText,
//   //             headers: { ...response.headers, 'x-sw-cache': new Date().getTime() },
//   //           });
//   //           cache.put(event.request, responseCopy);
//   //         }
//   //         return response;
//   //       }));
//   //     });
//   //   })
//   // );

//   // if (event.request.url.endsWith(".js")) {
//   //   console.info("check 2............request js url.....");
//   //   event.respondWith(

//   //     fetch(event.request)
//   //       .then(response => {
//   //         console.info("check 3............response.....", response);
//   //         if (response.status === 404) {
//   //           // Handle JavaScript 404 error
//   //           console.error(`Resource not found: ${event.request.url}`);

//   //           // clear cache and hard reload the page
//   //           console.info("clear cache before reload...");
//   //           if (caches) {
//   //             console.info("clearing cache...");
//   //             caches
//   //               .keys()
//   //               .then(function(names) {
//   //                 console.info("cache names", names);
//   //                 for (let name of names) caches.delete(name);
//   //               })
//   //               .finally(() => {
//   //                 console.info("now sending message to reload...");
//   //                 // location.reload(true);
//   //                 // window.location = "/";
//   //               });
//   //           }

//   //           // Post a message to the client to show a modal
//   //           self.clients.matchAll().then(clients => {
//   //             clients.forEach(client => {
//   //               client.postMessage({
//   //                 type: "SHOW_REFRESH_MODAL"
//   //               });
//   //             });
//   //           });
//   //           return new Response(""); // return an empty response or custom error message
//   //         }
//   //         return response;
//   //       })
//   //       .catch(error => {
//   //         // Handle network errors
//   //         console.error(`Network error occurred: ${error}`);
//   //         return new Response("");
//   //       })
//   //   );
//   // } else {
//   //   event.respondWith(
//   //     caches.match(event.request).then(response => {
//   //       return response || fetch(event.request);
//   //     })
//   //   );
//   // }
// });

// self.addEventListener('activate', (event) => {
//     const cacheWhitelist = ['static-v1'];
//     console.info("check 4............activate addEventListener");
//     event.waitUntil(
//         caches.keys().then((cacheNames) => {
//             return Promise.all(
//                 cacheNames.map((cacheName) => {
//                     console.info("check 5............cacheName.....", cacheName);
//                     if (cacheWhitelist.indexOf(cacheName) === -1) {
//                         return caches.delete(cacheName);
//                     }
//                 })
//             );
//         })
//     );
// });
